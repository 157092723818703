:root {
  --bg-color: #000102;
  --bg-hover: #000102;
  --btn-hover: #113168;
  --primary-text: rgb(221, 221, 221);
  --secondary-text: #bdbdbd;
  --active: #1c54b2;
  --divider: #9e9e9e;
  --danger: rgb(255, 113, 113);
  --warning: #ffab00;
  --tab-color: #1a2649;
  --tab-color-dark: #0c1222;
}

html, body {
  margin: 0;
  padding: 0; 
}

body, dialog {
  background-color: var(--bg-color);
  color: var(--primary-text);
}

dialog {
  border: 2px solid var(--tab-color);
  font-size: 14px;
  box-shadow: 2px 2px 6px 3px rgba(19, 19, 19, 0.692);
  border-radius: 0.25rem;
  max-width: 90%;
}

dialog::backdrop {
  background-color: rgba(70, 70, 70, 0.8);
}

div.dropzone {
    position: fixed; top: 0; left: 0; 
    z-index: 9999999999;               
    width: 100%; height: 100%;         
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 175ms;
}

td {
  color: var(--secondary-text)
}

header {
  text-align: center;
  margin: 3rem 0 2rem;
}

h1 {
  margin: 0;
  padding: 0;
}

input[type="file"] {
  opacity: 0;
  z-index: -1;
  position: absolute;
  height: 1px;
}

table {
  border-collapse: collapse;
}

dialog div table {
  width: 100%;
}

dialog div table th {
  background: var(--bg-color);
  position: sticky;
  top: 0;
}

input[type="text"] {
  background-color: var(--tab-color-dark);
  color: var(--primary-text);
  border: 1px solid var(--btn-hover);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

input:focus {
  background-color: var(--tab-color);
  outline: 2px solid var(--active);
}

tr.hover td {
  border-bottom: 1pt solid var(--btn-hover);
}

tr th {
  border-bottom: 1pt solid var(--divider);
}

td, th {
  padding: 0.25rem 1rem;
}

th button {
  background-color: var(--bg-color);
  color: var(--primary-text);
  border: 0;
  /* position: relative; */
  margin: 0;
  text-align: left;
  padding: 0;
  font-size: 0.95rem;
}

.up, .down {
  padding-left: 0.9rem;
}

.up::before, .down::before {
  position: absolute;
  left: 0.85rem;
  color: var(--active);
}

.up::before {
  content: '\25B2';
}

.down::before {
  content: '\25BC';
}

.card {
  border: 1px solid var(--tab-color);
  border-radius: 5px;
  padding: 0.5rem 1rem 1rem;
}

.file-select {
  cursor: pointer;
  font-size: 1.2em;
  padding: .5rem 1rem;
  border-radius: 4px;
  transition: 0.3s;
}
.file-select:hover {
  background: var(--tab-color);
  cursor: pointer;
  box-shadow: 2px 2px 2px 1px black;
}

.button {
  min-width: 100px;
  padding: 0.3rem 0.5rem;
  color: var(--primary-text);
  background-color: var(--tab-color);
  border: 1px solid var(--tab-color);
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 1px 1px 1px 1px black;
  margin: 5px;
  transition: 0.3s;
  font-size: 1.05em;
}

.tabs {
  padding: 0.1rem;
  background-color: var(--tab-color);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin: 0;
}

.tab-btn {
  min-width: 110px;
  padding: 0.3rem 0.5rem;
  color: var(--primary-text);
  background-color: var(--tab-color);
  border: 0;
  cursor: pointer;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin: 2px;
  transition: 0.3s;
  font-size: 1.08em;
}

.button:hover,
.tab-btn:hover:not(.active) {
  background: var(--btn-hover);
  cursor: pointer;
}
.tab-btn.active {
  background-color: var(--bg-color);
}
button:disabled {
  pointer-events: none;
  background-color: rgb(55, 55, 55);
  color: rgb(171, 171, 171);
}

#upload {
  text-align: center;
}

#results {
  margin: 0 auto;
}

.plain {
  color: var(--primary-text);
  background-color: var(--bg-color);
  border: 0;
  cursor: pointer;
  margin: 1rem 0;
  padding: 0;
}

.tabContent {
  display: none;
  margin: 1rem auto;
  min-width: 360px;
}

.danger {
  color: var(--danger);
  margin: 0.2rem;
  margin-left: 1.7rem;
}

.warning {
  color: var(--warning);
}

.hover {
  padding: .35rem .1rem;
  transition: 0.1s;
  border-bottom: 1px solid var(--tab-color);
}

.hover:hover {
  background-color: var(--tab-color-dark);
  border-bottom: 1px solid var(--active);
}
